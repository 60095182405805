<template>
  <div class="question-store-type container">
    <YForm v-model="tableForm" inline class="y-form" size="medium">
      <headerBar />
      <YField
        size="medium"
        prefix-icon="el-icon-search"
        name="content"
        placeholder="请输入题目内容"
      />
      <YField
        placeholder="题目类型"
        component="el-select"
        name="type"
        :data-source="QUESTONAIRE_QUESTION_TYPE"
      />
      <YButton do="search" class="btn btn_blue" />

      <YQueryTable
        ref="queryTable"
        :serve="getList"
        class="table"
        tooltip-effect="dark"
        row-key="id"
        :tree-props="{ children: 'children' }"
        default-expand-all
      >
        <template>
          <el-table-column
            label="题目内容"
            prop="content"
            show-overflow-tooltip
          />
          <el-table-column
            label="题目类型"
            prop="parentName"
            min-width="120"
            show-overflow-tooltip
            align="center"
          >
            <template slot-scope="scope">{{
              QUESTONAIRE_QUESTION_TYPE.get(scope.row.questionType)
            }}</template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            prop="createTime"
            min-width="120"
            show-overflow-tooltip
            align="center"
          />
          <el-table-column label="操作" prop="name" min-width="120">
            <template slot-scope="scope">
              <el-link class="mr8" @click="detail(scope.row)">查看详情</el-link>
            </template>
          </el-table-column>
        </template>
      </YQueryTable>
    </YForm>

    <el-dialog title="查看详情" :visible.sync="dialogShow">
      <div class="question-container">
        <div class="question-content">{{ currentQusetion.content }}</div>
        <template v-if="currentQusetion.questionType === 4">
          <div class="flex-between" style="margin-bottom: 12px">
            <el-input v-model="formValues.username" inline></el-input>
            <el-button @click="getInput" class="btn btn_blue">搜索</el-button>
          </div>

          <el-table :data="inputDetail">
            <template>
              <el-table-column
                min-width="120"
                label="提交问卷时间"
                prop="createTime"
                show-overflow-tooltip
              />
              <el-table-column
                min-width="120"
                label="用户名"
                prop="username"
                show-overflow-tooltip
              />
              <el-table-column
                min-width="120"
                label="答案"
                prop="selecterValue"
                show-overflow-tooltip
              />
            </template>
          </el-table>
          <pagination
            v-if="totalCount > 0"
            :pageSize="formValues.pageSize"
            :total="totalCount"
            @sizeChange="sizeChange"
            @pageChange="pageChange"
          />
        </template>
        <template v-else>
          <el-table :data="selectQuestionList">
            <el-table-column label="选项" prop="content" />
            <el-table-column label="数量" prop="count" />
            <el-table-column label="比例" prop="rate" />
          </el-table>
        </template>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  fetchQuestionaireListById,
  fetchinputQuestionStatistic,
  fetchSelectQuestionStatistic,
} from "@/apis/backStageEndAPI/examStageManagement/index.js";
import { QUESTONAIRE_QUESTION_TYPE } from "@/utils/enum";
import headerBar from "@/components/backStageComponent/layout/headerBar/headerBar";
import pagination from "@/components/backStageComponent/pagination";

export default {
  data() {
    return {
      QUESTONAIRE_QUESTION_TYPE,
      tableForm: {},
      dialogShow: false,
      selectQuestionList: [],
      inputDetail: [],
      loading: false,
      totalCount: 0,
      currentQusetion: {},
      formValues: {
        username: "",
        pageSize: 10,
        pageNumber: 1,
      },
    };
  },
  components: { headerBar, pagination },
  methods: {
    async getList({ formValues }) {
      const { questions = [] } = await fetchQuestionaireListById({
        ...formValues,
        surveyId: this.$route.query.id,
      });
      return {
        data: questions || [],
      };
    },
    sizeChange(val) {
      this.formValues.pageSize = val;
      this.getInput();
    },
    pageChange(val) {
      this.formValues.pageNumber = val;
      this.getInput();
    },
    async getSelect() {
      const { questionId } = this.currentQusetion;
      const { rows, all = 1 } = await fetchSelectQuestionStatistic({
        questionId,
      });
      const { count } = rows || {};
      this.selectQuestionList = (count || []).map((item) => {
        return {
          count: item.count,
          content: item?.selecter?.content,
          rate: `${((item?.count / all) * 100).toFixed(2)}%`,
        };
      });
      this.loading = false;
    },
    async getInput() {
      const { questionId } = this.currentQusetion;
      const { records = [], total = 0 } = await fetchinputQuestionStatistic({
        questionId,
        ...this.formValues,
        sortOrder: "asc",
      });
      this.totalCount = total;
      this.inputDetail = (records || []).map((item) => {
        return {
          ...item,
        };
      });
      this.loading = false;
    },
    async detail(row) {
      this.loading = true;
      this.dialogShow = true;
      const { questionType } = row;
      this.currentQusetion = row;
      if (questionType === 4) {
        this.getInput();
      } else {
        this.getSelect();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.question- {
  &container {
    padding: 20px;
    border-radius: 12px;
    background: #f4f9fd;
  }
  &content {
    margin-bottom: 12px;
    color: var(--colorBlue);
    font-size: 14px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
  }
}
</style>
